<template>
  <div class="breadcrumb-section">
    <el-breadcrumb
      class="breadcrumb-list"
      v-if="breadcrumbValue.length"
      separator-class="el-icon-arrow-right"
    >
      <template v-for="(item, index) in breadcrumbValue">
        <el-breadcrumb-item
          v-if="item.to"
          :to="item.to"
          :key="index"
          :replace="true"
          >{{ item.name }}</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else :key="item.name">{{
          item.name
        }}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      breadcrumbValue: [],
    };
  },
  watch: {
    update(val) {
      if (val) {
        this.getBreadcrumbValue();
      }
    },
  },
  created() {
    this.getBreadcrumbValue();
  },
  methods: {
    getBreadcrumbValue() {
      const { breadcrumb } = this;
      if (breadcrumb.length) {
        return breadcrumb;
      }
      const value = this.$route.matched
        .filter((data) => data.meta && data.meta.breadcrumb)
        .map((data) => ({
          name: data.meta.breadcrumb.title,
          to: data.meta.breadcrumb.replace
            ? { name: data.name }
            : data.meta.breadcrumb.activeRoute
            ? data.meta.breadcrumb.activeRoute
            : undefined,
        }));
      this.$set(this, "breadcrumbValue", value);
    },
  },
};
</script>

<style lang="less" scoped>
.el-breadcrumb {
  margin-left: 4px;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 16px;
}
</style>
