import Admin from "@/views/Admin.vue";

export default [
  {
    path: "admin",
    name: "Admin",
    component: { render: (h) => h("router-view") },
    meta: {
      nav: {
        icon: "",
        title: "管理中心",
      },
      breadcrumb: {
        title: "管理中心",
      },
    },
    children: [
      {
        path: "list",
        name: "AdminList",
        redirect: { name: "AdminListSelf" },
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "管理中心",
            replace: true,
          },
        },
        children: [
          {
            path: "",
            name: "AdminListSelf",
            component: Admin,
            permission: "",
            meta: {
              title: "极客学院-管理中心-管理中心",
              nav: {
                title: "管理中心",
              },
            },
          },
        ],
      },
    ],
  },
];
