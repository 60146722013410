<template>
  <el-button
    :class="[jikerClass, sizeClass, shadowClass]"
    :size="size"
    :type="type"
    :plain="plain"
    :round="round"
    :circle="circle"
    :loading="loading"
    :disabled="disabled"
    :icon="icon"
    @click="handleClick"
  >
    <template v-if="text">{{ text }}</template>
  </el-button>
</template>

<script>
export default {
  props: {
    jikerClass: String,
    text: String,
    shadow: {
      type: Boolean,
      default: false,
    },
    size: String,
    type: String,
    plain: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: String,
  },
  computed: {
    sizeClass() {
      return this.size ? "mini" : "big";
    },
    shadowClass() {
      return this.shadow ? "shadow" : "";
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>

<style lang="less">
/* 实例：
  **  <jiker-button
  **    text="添加学习班"
  **    :shadow="true"
  **    size="mini"
  **    type="primary"
  **    @click="xxx">
  **  </jiker-button>

  **  <jiker-button
  **    jiker-class="jiker-btn-round"
  **    text="添加学习班"
  **    :shadow="true"
  **    size="mini"
  **    type="primary"
  **    round
  **    icon="el-icon-plus"
  **    @click="xxx">
  **  </jiker-button>

  **  <jiker-button
  **    jiker-class="jiker-btn-plain jiker-btn-round"
  **    text="添加学习班"
  **    size="mini"
  **    plain
  **    round
  **    icon="el-icon-plus"
  **    @click="xxx">
  **  </jiker-button>

  **  <jiker-button
  **    jiker-class="jiker-btn-gradient"
  **    text="添加学习班"
  **    type="primary"
  **    round
  **    @click="xxx">
  **  </jiker-button>
*/

.shadow {
  box-shadow: 8px 10px 20px 0px rgba(46, 61, 73, 0.15);
  transition: all 0.6s;
  &:hover {
    box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
  }
}
.jiker-btn-gradient {
  background: linear-gradient(25deg, #ff9bfc, #a86dff 80%, #a869ff);
  border: none;
  box-shadow: 0 16px 14px rgba(125, 50, 228, 0.2);
  transition: all 0.6s;
  &:hover {
    background: linear-gradient(
      60deg,
      #ff7efb,
      #9248fe 80%,
      #8a36ff
    ) !important;
    box-shadow: 0 10px 10px rgba(125, 50, 228, 0.2) !important;
  }
}
.jiker-btn-round {
  display: flex;
  align-content: center;
  justify-content: center;
  transition: all 0.6s;
  overflow: hidden;
  i {
    font-weight: 600;
  }
  span {
    overflow: hidden;
  }
}
.jiker-btn-round.big {
  padding: 12px;
  min-width: 100px;
  span {
    margin: 0 6px 0 8px;
  }
}
.jiker-btn-round.mini {
  padding: 8px;
  min-width: 50px;
  span {
    margin: 0 4px 0 6px;
  }
}
.jiker-btn-plain {
  color: #0fc700;
  background: transparent;
  border-color: #0fc700;
  &:hover {
    background: transparent !important;
  }
}
</style>
