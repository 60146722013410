<template>
  <div class="page-container">
    <div class="welcome-page">极客学院后台管理系统</div>
    <div class="tip">
      <div>Hi，{{ nickName }}！欢迎使用极客学院后台管理系统</div>
      <p>申请权限请联系：shengdandan@jiker.com，并邮件抄送部门负责人。</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["nickName"]),
  },
};
</script>

<style lang="less" scoped>
.welcome-page {
  margin: 20px;
  font-size: 36px;
  color: #141f29;
}
.tip {
  margin: 20px;
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-left: 5px solid #50bfff;
  border-radius: 4px;
  div {
    margin: 12px 0;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5em;
    color: #5e6d82;
  }
  p {
    margin: 12px 0;
    font-size: 14px;
    line-height: 1.5em;
    color: #141f29;
  }
}
</style>
