import User from "@/views/User.vue";

export default [
  {
    path: "user",
    name: "User",
    component: { render: (h) => h("router-view") },
    meta: {
      nav: {
        icon: "",
        title: "用户中心",
      },
      breadcrumb: {
        title: "用户中心",
      },
    },
    children: [
      {
        path: "list",
        name: "UserList",
        redirect: { name: "UserListSelf" },
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "用户列表",
            replace: true,
          },
        },
        children: [
          {
            path: "",
            name: "UserListSelf",
            component: User,
            permission: "",
            meta: {
              title: "极客学院-用户中心-用户列表",
              nav: {
                title: "用户列表",
              },
            },
          },
        ],
      },
    ],
  },
];
