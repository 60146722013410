import Home from "@/views/Home";

export default [
  {
    path: "home",
    name: "Home",
    component: Home,
    meta: {
      title: "极客学院-后台管理",
      nav: {
        icon: "el-icon-s-home",
        title: "首页",
      },
    },
  },
];
