import Pay from "@/views/Pay.vue";

export default [
  {
    path: "pay",
    name: "Pay",
    component: { render: (h) => h("router-view") },
    meta: {
      nav: {
        icon: "",
        title: "支付中心",
      },
      breadcrumb: {
        title: "支付中心",
      },
    },
    children: [
      {
        path: "list",
        name: "PayList",
        redirect: { name: "PayListSelf" },
        component: { render: (h) => h("router-view") },
        meta: {
          breadcrumb: {
            title: "支付记录",
            replace: true,
          },
        },
        children: [
          {
            path: "",
            name: "PayListSelf",
            component: Pay,
            permission: "",
            meta: {
              title: "极客学院-支付中心-支付记录",
              nav: {
                title: "支付记录",
              },
            },
          },
        ],
      },
    ],
  },
];
