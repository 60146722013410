import Vue from "vue";
import Vuex from "vuex";
import Cookie from "js-cookie";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    authPermissions: [],
  },
  getters: {
    userAvatar() {
      return Cookie.get("avatar");
    },
    nickName() {
      return Cookie.get("nickname") || Cookie.get("uname");
    },
    uName() {
      return Cookie.get("uname");
    },
  },
  mutations: {
    getAuthPermissions(state, authPermissions) {
      state.authPermissions = authPermissions;
    },
  },
  actions: {
    logout() {
      const DOMAIN = process.env.VUE_APP_DOMAIN;
      Cookie.remove("uid", { domain: DOMAIN });
      Cookie.remove("token", { domain: DOMAIN });
      Cookie.remove("uname", { domain: DOMAIN });
      Cookie.remove("avatar", { domain: DOMAIN });
      Cookie.remove("_vip_token", { domain: DOMAIN });
      Cookie.remove("nickname", { domain: DOMAIN });
      const referer = window.location.href;
      const ENV_PREFIX = process.env.NODE_ENV === "uat" ? "uat-" : "";
      const PASSPORT_PREFIX =
        `https://${ENV_PREFIX}account` + process.env.VUE_APP_DOMAIN;
      window.location.href = PASSPORT_PREFIX + "/?referer=" + referer;
    },
  },
});

export default store;
