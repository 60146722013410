import TheLayout from "@/components/TheLayout.vue";

import routesAdmin from "./routesAdmin";
import routesPay from "./routesPay";
import routesUser from "./routesUser";
import routesHome from "./routesHome";
import Login from "@/views/Login";
export default [
  {
    path: "/",
    component: TheLayout,
    redirect: { name: "Home" },
    children: [...routesHome, ...routesUser, ...routesPay, ...routesAdmin],
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
];
