<template>
  <div>
    <bread-crumb></bread-crumb>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped></style>
