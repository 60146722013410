<template>
  <div class="login-page">
    <div class="login-page-mainer">
      <div class="login-logo-box">
        <img class="login-logo" src="@/assets/images/logo.png" />
        <div class="login-logo_text">极客学院管理后台</div>
      </div>
      <!-- <div class="switch-login">
        <div
          :class="['switch-item', { active: loginType }]"
          @click="switchLogin"
        >
          密码登录
        </div>
        <div
          :class="['switch-item', { active: !loginType }]"
          @click="switchLogin"
        >
          验证码登录
        </div>
      </div> -->
      <el-form
        class="login-info"
        :model="loginInfo"
        :rules="loginRules"
        ref="loginInfo"
        status-icon
        v-show="loginType"
      >
        <el-form-item prop="username">
          <el-input
            v-model="loginInfo.username"
            placeholder="用户名"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" :show-message="false">
          <el-input
            v-model="loginInfo.password"
            type="password"
            placeholder="密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="login-btn"
            type="primary"
            plain
            @click="login"
            :loading="btnLoading"
            >{{ btnLoading ? "登录中" : "登录" }}</el-button
          >
        </el-form-item>
      </el-form>
      <el-form
        class="login-info"
        ref="phoneLogin"
        :rules="phoneRules"
        :model="phoneLogin"
        v-show="!loginType"
      >
        <el-form-item prop="username">
          <el-input
            v-model="phoneLogin.username"
            placeholder="手机号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="sms_code" :show-message="false">
          <div class="flex">
            <el-input
              v-model="phoneLogin.sms_code"
              placeholder="验证码"
              autocomplete="off"
            ></el-input>
            <el-button @click="hanldeSendCode" :disabled="isDisabled">{{
              buttonName
            }}</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            class="login-btn"
            type="primary"
            plain
            @click="handlePhoneLogin"
            :loading="btnLoading"
          >
            {{ btnLoading ? "登录中" : "登录" }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// import userService from "@/global/service/user";

export default {
  data() {
    return {
      btnLoading: false,
      loginType: true,
      loginInfo: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, message: " ", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "手机号码格式有误，请重新填写",
            trigger: "blur",
          },
        ],
        password: { required: true, trigger: "blur" },
      },
      phoneLogin: {
        username: "",
        sms_code: "",
      },
      phoneRules: {
        username: [
          { required: true, message: " ", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "手机号码格式有误，请重新填写",
            trigger: "blur",
          },
        ],
        sms_code: [{ required: true, trigger: "blur" }],
      },
      buttonName: "发送验证码",
      buttonTime: 60,
      isDisabled: false,
    };
  },
  methods: {
    login() {
      //   this.$refs["loginInfo"].validate((val) => {
      //     if (!val) return;
      //     this.btnLoading = true;
      //     userService
      //       .login(this.loginInfo, { withCredentials: true })
      //       .then(() => {
      //         this.$message.success("登录成功");
      //         this.$router.push({
      //           name: "Home",
      //         });
      //       })
      //       .finally(() => {
      //         this.btnLoading = false;
      //       });
      //   });
      this.$router.push({
        name: "Home",
      });
    },
    handlePhoneLogin() {},
    switchPwdLogin() {},
    switchLogin() {
      if (this.loginType) {
        this.$refs["loginInfo"].resetFields();
        this.loginType = false;
      } else {
        this.$refs["phoneLogin"].resetFields();
        this.loginType = true;
      }
    },
    switchPhoneLogin() {},
    hanldeSendCode() {
      if (this.phoneLogin.username === "") {
        this.$message.error("请输入手机号");
        return;
      } else {
        this.buttonName = this.buttonTime + "秒后重新发送";
        this.isDisabled = true;
        let interval = window.setInterval(() => {
          this.buttonName = --this.buttonTime + "秒后重新发送";
          if (this.buttonTime <= 0) {
            this.buttonName = "获取验证码";
            this.buttonTime = 60;
            this.isDisabled = false;
            window.clearInterval(interval);
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less">
.login-page {
  position: relative;
  height: 100vh;
  background: #f3fff24a;
  .login-page-mainer {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    text-align: center;
    .login-logo-box {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .login-logo {
        height: 60px;
        width: auto;
      }
      .login-logo_text {
        margin-left: 24px;
        height: 60px;
        font-size: 20px;
        line-height: 60px;
      }
    }
    .login-info {
      margin-top: 35px;
      .el-link--inner {
        font-size: 14px;
        font-weight: 500;
      }
      .login-btn {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
}
.flex {
  display: flex;
}
.el-link {
  /deep/.el-link--inner {
    font-size: 16px;
    color: #999;
    &:hover {
      color: #409eff;
    }
  }
}
.switch-login {
  height: 35px;
}
.switch-item {
  font-size: 14px;
  display: inline-block;
  padding-bottom: 6px;
  margin: 0 20px;
  border-bottom: 4px solid transparent;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  &.active {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333;
    border-color: #1764e8;
  }
}
</style>
