import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "../theme/index.css";

import BreadCrumb from "./components/BreadCrumb.vue";
import JikerButton from "./components/JikerButton.vue";
Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.component("jiker-button", JikerButton);
Vue.component("bread-crumb", BreadCrumb);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
